<template>
  <v-card height='100px'>
    <div style='margin-left: 20px'>
      <!--<div class='subheading'>Examination:</div>-->
      <v-layout row wrap>
        <v-flex xs6> Weight (Kg): {{ exam.Weight }} </v-flex>
        <v-flex xs6>Height (cms): {{ exam.Height }} </v-flex>
        <v-flex xs6>Pulse Rate (bpm): {{ exam.PR }} </v-flex>
        <v-flex xs6> Blood Pressure (mmHg): {{ exam.BP }} </v-flex>
      </v-layout>
    </div>
  </v-card>
</template>

<script>
export default {
  props: ['exam']
};
</script>

<style scoped></style>
