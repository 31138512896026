<template>
  <v-dialog v-model='dlgDig' scrollable persistent max-width='600px'>
    <v-card>
      <v-card-title>
        <div class='title font-weight-medium'>
          {{ dlgType === 0 ? 'Add' : dlgType === 1 ? 'Edit' : 'View' }}
          diagnose details
        </div>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-combobox
            v-model="selectedItems"
            :items="items"
            label="Select Diagnoses or add by typing & press Enter"
            multiple
            chips
        ></v-combobox>
        <div v-show='submit.error'>
          <v-list two-line>
            <v-list-item>
              <span class='red--text'>{{ submit.message }}</span>
            </v-list-item>
          </v-list>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text :disabled='submit.working' @click='closeDlg'>Close</v-btn>
        <v-btn
            color='primary darken-1'
            text
            :loading='submit.working'
            @click='saveDlg'
            :disabled='dlgType === 2'
        >Save
        </v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import WebH from '../../api/web';

export default {
  props: {
    pid: {type: Number, default: 0},
    dlgDig: {type: Boolean, default: false},
    dlgType: {
      type: Number,
      default: 0
    },
    obj: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  watch: {
    dlgDig: function () {
      if (this.obj != null && this.obj.Id && this.selectedItems.length < 1) {
        this.selectedItems = this.obj.JDiagnosis;
      }
    }
  },
  data() {
    return {
      submit: {
        error: false,
        working: false,
        message: ''
      },
      selectedItems: [],
      items: [
        "CH. AR",
        "CH. ARC",
        "CH. ANB",
        "CH. AC",
        "CH. URTICARIA",
        "AC. AR",
        "AC. ARC",
        "AC. ANB",
        "AC. URTICARIA",
        "AD",
        "FOOD ALLERGY",
        "DRUG ALLERGY",
        "Anaphylaxis",
        "Angioneurotic Edema",
        "Pompholyx",
        "Allergic Dermatosis",
        "Contact dermatitis",
        "Hereditary Angioneurotic Edema",
        "Airborne Contact Dermatitis",
        "Physical Urticaria",
        "Idiopathic Urticaria",
        "Vasomotor Rhinitis",
        "COPD",
        "Atopic Dermatitis",
      ],
    };
  },
  methods: {
    clearFields() {
      this.submit = {
        error: false,
        working: false,
        message: ''
      };
      this.selectedItems = [];
    },
    closeDlg() {
      this.clearFields();
      this.$emit('close');
    },
    async saveDlg() {
      if (this.pid < 1 && this.dlgType === 0) {
        await this.$notifyDlg.show('Invalid patient', 'Error', 'error');
        return;
      }

      const item = {
        JDiagnosis: this.selectedItems
      };
      if (this.dlgType === 0) {
        item.PId = this.pid;
        this.api_create(item);
      } else {
        item.Id = this.obj.Id;
        this.api_update(item);
      }
    },
    async api_create(data) {
      this.submit.working = true;
      const res = await WebH.Post('/diagnoses', data);
      this.submit.working = false;
      if (res.Success) {
        this.clearFields();
        this.$emit('add', res.Data);
        this.closeDlg();
      } else {
        this.submit.error = true;
        this.submit.message = res.Data;
      }
    },
    async api_update(data) {
      const res = await WebH.Put('/diagnoses/' + data.Id, data);
      this.submit.working = false;
      if (res.Success) {
        this.clearFields();
        this.$emit('update');
        this.closeDlg();
      } else {
        this.submit.error = true;
        this.submit.message = res.Data;
      }
    }
  }
};
</script>

<style scoped></style>
