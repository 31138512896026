<template>
  <v-card height='100px'>
    <v-layout>
      <v-flex xs2>
        <v-icon class='fa-5x' style='margin-left: 10px;margin-right: 10px'
          >fa fa-user</v-icon
        >
      </v-flex>
      <v-flex>
        <div>
          <div class='subheading'>
            {{ patient.Name }} &nbsp;&nbsp; (&nbsp;{{
              patient.PatientId
            }}&nbsp;)
          </div>
          <br />
          <div>&#x260E;&nbsp;&nbsp;{{ patient.Mobile }}</div>
        </div>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
export default {
  props: ['patient']
};
</script>

<style scoped></style>
